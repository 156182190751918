<template>
  <div ref="load">Загрузка</div>
</template>

<script>
import { getTemplate, postInvoiceNumber } from "@/api/template";
export default {
  created() {
    localStorage.setItem(
      "templateId",
      JSON.stringify(this.$route.query.template)
    );
  },
  mounted() {
    getTemplate().then((res) => {
      let cardBlock = {
        ...res,
        template_id: JSON.parse(localStorage.getItem("templateId")),
      };
      const invoice = postInvoiceNumber(cardBlock);
      invoice
        .then((res) => {
          this.$router.push(`/checkout/fix/${res.data.internal_id}`);
        })
        .catch(() => {
          this.$refs.load.innerText =
            "Ошибка получения заказа. Попробуйте ещё раз";
        });
    });
  },
};
</script>
